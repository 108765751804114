import React from "react";
import FeatureCard from "./partials/FeatureCard";

const Features = () => {
  return (
    <div className="px-6 py-6 mx-auto space-y-2 text-center lg:py-24">
      <div className="py-6 space-y-6">
        <p className="text-base font-bold tracking-widest uppercase text-primary-600">
          Optimize Business Operations{" "}
        </p>
        <h2 className="max-w-4xl mx-auto text-4xl font-bold lg:px-16 lg:text-5xl text-neutral-1000">
          Streamline Operations, Reduce Costs & Increase Revenue
        </h2>
      </div>
      <div className="grid gap-2 pt-6 mx-auto max-w-7xl md:grid-cols-2 gap-y-16 place-items-center">
        <FeatureCard
          title="Product inventory"
          description="Intuitive and smart product inventory management."
          img="feature-1"
          href="/explore/inventory-management"
        ></FeatureCard>
        <FeatureCard
          title="Custom Website Widgets"
          description="Use customizable widgets for optimal website integration."
          img="feature-2"
          href="/sell/booking-widget"
        ></FeatureCard>
        <FeatureCard
          title="Reporting"
          description="Operational reporting to understand your business better."
          img="feature-3"
          href="/explore/reports"
        ></FeatureCard>
        <FeatureCard
          title="Team Management"
          description="Assign and manage your staff's roles and permission access."
          img="feature-4"
          href="/explore/staff-management"
        ></FeatureCard>
      </div>
    </div>
  );
};

export default Features;
