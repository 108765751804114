import Image from "next/image";
import React from "react";

const WhyChooseUs = () => {
  return (
    <div>
      <div className="bg-gradient-to-l from-[#FDEED0]/30  via-[#94D8FB]/30 to-[#F1E1F9]/30 mx-auto text-center py-12 space-y-2">
        <div className="max-w-2xl px-6 py-8 mx-auto space-y-10 lg:max-w-7xl">
          <div className="space-y-2">
            <p className="text-base font-bold tracking-widest uppercase text-primary-600">
              Features
            </p>
            <h2 className="max-w-lg mx-auto text-4xl font-bold text-neutral-1000">Why Choose Us</h2>
          </div>
          <div className="grid grid-cols-1 gap-4 py-6 lg:grid-cols-3">
            {/* Card 1 */}
            <div className="px-6 py-8 space-y-3 bg-white rounded-lg">
              <div className="flex items-center justify-center">
                <Image src="/site/why-choose-us/icon-1.png" alt="" width={70} height={70} />
              </div>
              <h4 className="text-xl font-bold text-neutral-1000">FREE Online Waivers</h4>
              <p className="text-[#3E4C5B] font-normal text-md">
                We valve our partnership. We are proud to offer our OnlineWaiverPro Waivers for FREE
                to SmartRez customers. Save Time and Money.
              </p>
            </div>

            {/* Card 2 */}
            <div className="px-6 py-8 space-y-3 bg-white rounded-lg">
              <div className="flex items-center justify-center">
                <Image src="/site/why-choose-us/icon-2.png" alt="" width={70} height={70} />
              </div>
              <h4 className="text-xl font-bold text-neutral-1000">Fully Integrated</h4>
              <p className="text-[#3E4C5B] font-normal text-md">
                All-in-One solution. Everything on one platform: Reservations, Waivers, and more.
              </p>
            </div>

            {/* Card 3 */}
            <div className="px-6 py-8 space-y-3 bg-white rounded-lg">
              <div className="flex items-center justify-center">
                <Image src="/site/why-choose-us/icon-3.png" alt="" width={70} height={70} />
              </div>
              <h4 className="text-xl font-bold text-neutral-1000">Dedicated Customer Service</h4>
              <p className="text-[#3E4C5B] font-normal text-md">
                Our dedicated customer service team is here to support your needs. Reach us via
                phone, live chat or email.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
